import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";
import * as stsReducer from "../reducers/sts.reducer";
import { PlasmaAdmins } from "../models/admins";
import { CanAccessCheckResults } from "../models/check-result-access";
import { CanAccessUserManagement, CanAccessUserManagementDK } from "../models/user-management-access";
import { appLogo, claimTypes, userTypes, claimValue, configType, whiteLabelNames, ELoginMethod, } from "src/app/appsettings";
import { EnvConfig } from "src/app/service/config/config.service";
import { findClaimAndGetValue } from 'src/app/models/claims.model';
import { LoggerService } from "src/app/service/logger.service";

const State = (state: AppState) => state[stsReducer.stsFeatureKey];
const logr = new LoggerService(null);

const getFirstName = createSelector(State, (data) => data.given_name);
const getLastName = createSelector(State, (data) => data.family_name);
const getName = createSelector(State, (data) => data?.name || "");
const getApplicationType = createSelector(State, (data) => getEnvConfig(data.config, configType.TYPE) );
const getApplicationEnvironment = createSelector(State, (data) => getEnvConfig(data.config, configType.ENVIRONMENT) );
const getApiEndpoint = createSelector(State, (data) => getEnvConfig(data?.config || {}, configType.BASE_PLASMA_URL));
const getBaseUrl = createSelector(State, (data) => getEnvConfig(data.config, configType.BASE_PLASMA_URL) );
const getBaseAdminUrl = createSelector(State, (data) => getEnvConfig(data.config, configType.BASE_ADMIN_URL) );
const getEmail = createSelector(State, (data) => data.email);
const getCountry = createSelector(State, (data) => data.country);
const getConfig = createSelector(State, (data) => data.config);

/** admin = co_admin | pt_admin | dk_admin */
const isPlasmaWebAdmin = createSelector(State, (data) => data.role?.some((n) => PlasmaAdmins.some((m) => m == n)) );
const canAccessCheckResults = createSelector(State, (data) => data?.role?.some((n) => CanAccessCheckResults.some((m) => m == n)) );
const isCustomerCare = createSelector(State, (data) => data.role?.some((n) => n === userTypes.CUSTOMER_CARE) );
const isNotWhiteLabled = createSelector(State, (data) => getEnvConfig(data?.config, configType.WHITE_LABEL_ENABLED).toLowerCase() == claimValue.FALSE );
const getWhiteLabelName = createSelector(State, (data) => getEnvConfig(data?.config, configType.WHITE_LABEL_NAME).toLowerCase());
const canAccessUserManagement = createSelector(State, (data) => {
  const isDKTenant = checkTenant(whiteLabelNames.COVID_DK, data.config);
  const isDFPTenant = checkTenant(whiteLabelNames.DFP, data.config);
  let canAccess = CanAccessUserManagement
  if (isDKTenant || isDFPTenant) {
    canAccess = [...CanAccessUserManagement, ...CanAccessUserManagementDK];
  }
  return data.role?.some((n) => canAccess.some((m) => m == n))
});

const isSchoolUser = createSelector(State, (data) => data.role?.some( (n) => n === userTypes.SCHOOL_TEACHER || n === userTypes.SCHOOL_ADMIN ) );
const getAppLogo = createSelector(State, (data) => {
  const isDKTenant = checkTenant(whiteLabelNames.COVID_DK, data.config);

  if (data.config === null) { return appLogo.LOADING; }
  else if (isDKTenant) { return appLogo.COVID_DK; }
  else { return appLogo.SYNLAB; }
});

const getAppIsWhiteLabeled = createSelector(State, (data) => {
  return ( getEnvConfig(data.config, configType.WHITE_LABEL_ENABLED).toLowerCase() == claimValue.TRUE );
});

const getbaseProfileUrl = createSelector(State, (data) => { return getEnvConfig(data?.config, configType.BASE_PROFILE_URL); });
const getBaseBookingUrl = createSelector(State, (data) => getEnvConfig(data.config, configType.BASE_BOOKING_URL) );
const getBaseBigcommerceUrl = createSelector(State, (data) => getEnvConfig(data.config, configType.BASE_BIGCOMMERCE_URL) );
const getEEConsultationUrl = createSelector(State, (data) => getEnvConfig(data.config, configType.EE_CONSULTATION_URL) );
const getEEHometestUrl = createSelector(State, (data) => getEnvConfig(data.config, configType.EE_HOMETES_URL) );
const getBookingLinkEnabled = createSelector(
  State,
  (s) => getEnvConfig(s.config, configType.BOOKING_LINK_ENABLED).toLowerCase() == claimValue.TRUE &&
    s.role.every((r: any) =>
        [ userTypes.SCHOOL_ADMIN, userTypes.SCHOOL_TEACHER, userTypes.LOLLI_SUPER_ADMIN,
          userTypes.BCP, userTypes.WEBREQ_USER, userTypes.LOLLI_LAB_USER,
        ].indexOf(r) === -1
    ));
const getFOBTProductCodeCampaign = createSelector(State, (data) => getEnvConfig(data.config, configType.FOBT_PRODUCT_CODE_CAMPAIGN) );


const isSchoolAdmin = createSelector(State, (data) => data.role?.some((n) => n === userTypes.SCHOOL_ADMIN) );
const isSchoolTeacher = createSelector(State, (data) => data.role?.some((n) => n === userTypes.SCHOOL_TEACHER) );
const isLolliAdmin = createSelector(State, (data) => data.role?.some((n) => n === userTypes.LOLLI_SUPER_ADMIN) );
const isLolliLabAdmin = createSelector(State, (data) => data.role?.some((n) => n === userTypes.LOLLI_LAB_ADMIN) );
const isDFPAdmin = createSelector(State, (data) => data.role?.some((n) => n === userTypes.UK_DFP_ADMIN));
const isSHFYAdmin = createSelector(State, (data) => data.role?.some((n) => n === userTypes.UK_SHFY_ADMIN));
const isMassTester = createSelector(State, (s) => s.role.some((x) => x === userTypes.MASS_TESTER) );
const isCompanyEmployee = createSelector(State, (data) =>
  [userTypes.SYNLAB_ACCESS_USER, userTypes.MASS_COMPANY].every((reqRole) =>
    data?.role.includes(reqRole)
  )
);
const isLolliUser = createSelector(
  isSchoolAdmin, isSchoolTeacher, isLolliAdmin,
  isLolliLabAdmin, isMassTester, isCompanyEmployee,
  (...isLolli) => isLolli.some((x) => x)
);
const getUserId = createSelector(State, (data) => data.sub);

const isLabUser = createSelector(State, (data) =>
  data.role?.some((n) => n === userTypes.LOLLI_LAB_USER)
);

const getIsInvoiceColumnEnabled = createSelector(State, (data) => {
  // KJDA SGH-168 Remove Invoice column for School and Company.
  const isDK = checkTenant(whiteLabelNames.COVID_DK, data.config);
  return !(
      isDK || data.role.every((role: any) => [ userTypes.SCHOOL_ADMIN, userTypes.SCHOOL_TEACHER,
          userTypes.LOLLI_SUPER_ADMIN, userTypes.LOLLI_LAB_ADMIN,
        ].indexOf(role) > -1) ||
      data.role.some((role: any) =>
        [ userTypes.MASS_TESTER, userTypes.MASS_COMPANY,
          userTypes.SYNLAB_ACCESS_USER ].indexOf(role) > -1
    )
  );
});

const isSchoolAndABCompany = createSelector(
  State,
  (data) =>
    data.role.every(
      (role: any) =>
        [userTypes.SCHOOL_ADMIN, userTypes.SCHOOL_TEACHER].indexOf(role) > -1
    ) ||
    data.role.some(
      (role: any) =>
        [
          userTypes.MASS_TESTER,
          userTypes.MASS_COMPANY,
          userTypes.SYNLAB_ACCESS_USER,
        ].indexOf(role) > -1
    )
);

/** User has hungary_football as usertype */
const isHungaryFootballUser = createSelector( State, (data) => data.role.indexOf(userTypes.HUNGARY_FOOTBALL) > -1 );
const isMassUser = createSelector(State, (data) => {
  return data.role.some((role: any) => {
    return [userTypes.MASS_BAVARIA, userTypes.MASS_PORSCHE].includes(role);
  });
});

const canAccesspoolIndividualOrdersPage = createSelector(State, (user) => user.role?.some((role) => role === userTypes.LOLLI_LAB_USER) );
const canAccesspoolGenerateOrdersPage = createSelector(State, (user) => user.role?.some((role) => role === userTypes.LOLLI_LAB_USER) );

/** Determine redirect path */
const getRedirectPath = createSelector(State, (data) => {
  const usertype: string[] = Array.isArray(data.role) ? data.role : [data.role];
  const isDK = checkTenant(whiteLabelNames.COVID_DK, data.config);
  const isHungary = checkTenant(whiteLabelNames.HUNGARY, data.config);
  const isEE = checkTenant(whiteLabelNames.ESTONIA, data.config);
  const scanRedirectTypes = [userTypes.LOLLI_LAB_USER];
  const checkResultRedirectTypes = [ userTypes.PT_ADMIN, userTypes.CUSTOMER_CARE, userTypes.SUPERADMIN, userTypes.USERADMIN, userTypes.EE_ADMIN, ];
  const whiteLabelCheckResultRedirectTypes = [ userTypes.PT_ADMIN, userTypes.SUPERADMIN, userTypes.USERADMIN, ];
  const userEditorRedirectTypes = [ userTypes.LOLLI_LAB_ADMIN ];
  const myResultRedirectTypes = [
    userTypes.CUSTOMER_CARE, userTypes.SCHOOL_ADMIN, userTypes.SCHOOL_TEACHER,
    userTypes.LOLLI_SUPER_ADMIN, userTypes.MASS_TESTER, userTypes.SYNLAB_ACCESS_VERIFIER,
    userTypes.SYNLAB_ACCESS_USER, userTypes.PATIENT, userTypes.DKAMIN,
    userTypes.MASS_TESTER, userTypes.LOLLI_LAB_ADMIN, userTypes.UK_DFP_ADMIN, userTypes.UK_SHFY_ADMIN
  ];

  logr.log(isHungary, usertype, data)

  const useNewCheckResults: boolean = getEnvConfig(data.config, 'UseNewCheckResults').toLocaleLowerCase() == claimValue.TRUE;
  const fullCatalogueViewEnabled: boolean = getEnvConfig(data.config, 'FullCatalogueViewEnabled').toLocaleLowerCase() == claimValue.TRUE;
  const isAdminUser: boolean = usertype.some((ut: any) => checkResultRedirectTypes.indexOf(ut) > -1);
  const newResultsRoute = ["/results", "category"];

  if (useNewCheckResults && fullCatalogueViewEnabled && isAdminUser) {
    return newResultsRoute;
  }
  else if ((isHungary || isEE) && fullCatalogueViewEnabled) {
    return newResultsRoute
  }
  else if (usertype.some((ut : userTypes) => userEditorRedirectTypes.indexOf(ut) > -1)) {
    return ["/results/user-editor"];
  }
  else if (usertype.some((ut: any) => scanRedirectTypes.indexOf(ut) > -1)) {
    return ["/lab-order"];
  }
  else if (isAdminUser) {
    return ["/results", "check"];
  }
  else if (usertype.some((ut: any) => myResultRedirectTypes.indexOf(ut) > -1)) {
    return fullCatalogueViewEnabled ? newResultsRoute : ["/results","list"];
  }
  else {
    return ["/not-found"];
  }
});

const isMassCompany = createSelector(State, (data) => { return data.role.some((role: any) => { return [userTypes.MASS_COMPANY].includes(role); }); });
const isCompanyAdmin = createSelector( State, (s) => s.role.includes(userTypes.MASS_COMPANY) && s.role.includes(userTypes.SCHOOL_ADMIN) );

/** get user's usertypes */
const getRole = createSelector(State, (data) => data.role);
const getSynlabId = createSelector(State, (data) => data.synlab_id);
const getClaims = createSelector(State, (data) => data.claims);
const getPassport = createSelector(getClaims, claims => findClaimAndGetValue(claimTypes.PASSPORT_NUMBER, claims));
const getSocialSecurityNumber = createSelector(getClaims, claims => findClaimAndGetValue(claimTypes.SSN, claims));
const getHasPassport = createSelector(getPassport, passport => typeof passport === 'string' && passport.length > 0)
const getSchoolSynlabId = createSelector( State, (data) => data?.claims?.find((x) => x.claimType === claimTypes.SCHOOL_SYNLAB_ID)?.claimValue );
const getClaimsLoading = createSelector(State, (data) => data.claimsLoading);
const getClaimsError = createSelector(State, (data) => data.claimsError);

const usertypeWithFrontEndTestTypeFilter = [ userTypes.SCHOOL_ADMIN, userTypes.SCHOOL_TEACHER, userTypes.MASS_TESTER, ];
const getUsertypeWithFrontEndTestTypeFilter = createSelector(getRole, (roles) => usertypeWithFrontEndTestTypeFilter.some((ut) => roles.indexOf(ut) > -1) );

const patientInformation = createSelector(State, (data) => ({
  firstName: data.given_name,
  lastName: data.family_name,
  dateOfBirth: data.claims
    ?.filter((n) => n.claimType == claimTypes.DATE_OF_BIRTH)
    ?.map((n) => n.claimValue)[0],
}));

const isAppAccessUser = createSelector(State, (data) => data?.role.includes(userTypes.SYNLAB_ACCESS_USER) );
const isPTAdminUser = createSelector(State, (data) => data?.role.includes(userTypes.PT_ADMIN) );
const getProfile = createSelector(State, (data) => data?.profile);
const getMFAEnabledValue = createSelector(
  State, (data) => data?.profile
      ?.find((x) => x.claimType === claimTypes.TWO_FACTOR_ENABLED)
      ?.claimValue?.toLocaleLowerCase() == claimValue.TRUE
);

const isDKTenant = createSelector(State, (data) => { return checkTenant(whiteLabelNames.COVID_DK, data.config); });
const isDFPTenant = createSelector(State, (data) => checkTenant(whiteLabelNames.DFP, data.config));
const isEETenant = createSelector(State, (data) => { return checkTenant(whiteLabelNames.ESTONIA, data.config); });
const isFITenant = createSelector(State, (data) => { return checkTenant(whiteLabelNames.FINLAND, data.config); });
const isCzechRepublic = createSelector(State, (data) => { return checkTenant(whiteLabelNames.CZECH_REPUBLIC, data.config); });
const isHuTenant = createSelector(State, (data) => { return checkTenant(whiteLabelNames.HUNGARY, data.config); });
const isILISCodeDE = createSelector(State, data =>
  data?.claims?.find?.(x => x.claimType === claimTypes.ILIS_CODE)?.claimValue
    .slice(-2) == claimValue.DE_ILIS_CODE_SUFFIX)
const getPreventionWellnessConfig = createSelector(State, data => ({ authId: data.config.PreventionWellnessAuthTokenUserIdProperty }));

const getCurrentUserToken = createSelector(State, data => ({ token: data.token, }));
/** previously fetched claims */
const fetchedClaimRecord = createSelector(State, data => data.fetchedClaimRecord);
const isPreventionWellnessUser = createSelector(getProfile, (profile) => {
  return !!profile
    && profile?.find((x) => x.claimType === claimTypes.GENEPLANET_USER)?.claimValue?.toLocaleLowerCase() == claimValue.TRUE
    && profile?.find((x) => x.claimType === claimTypes.ANONYMOUS_USER)?.claimValue?.toLocaleLowerCase() != claimValue.TRUE
})

const getRedirectURLFromQueryParam = createSelector(State, data => data.redirectUrl);
const getEnablePreventionWellness = createSelector( State, (data) => data.config?.EnablePreventionWellness?.toLowerCase() == claimValue.TRUE );
const getRequireMFA = createSelector( State, (data) => data.config?.RequireMFA?.toLowerCase() == claimValue.TRUE );

const isSuperSupport = createSelector(State, (data) => {
  const accessPerTenant = {
    [whiteLabelNames.ESTONIA]: [userTypes.EE_ADMIN],
    [whiteLabelNames.DFP]: [userTypes.UK_DFP_ADMIN],
    [whiteLabelNames.SHFY]: [userTypes.UK_SHFY_ADMIN],
    [whiteLabelNames.HUNGARY]: [userTypes.PT_ADMIN]
  };
  const userTypesToCheck = (data?.config.WhiteLabelName in accessPerTenant) ?
    accessPerTenant[data.config.WhiteLabelName] :
    [userTypes.PT_ADMIN];
  return data?.role.some((role: string) => userTypesToCheck.includes(role))
    && isClaimTrue(claimTypes.ALLOW_DELETE_USER, data?.profile);
});

const getProfileLocale = createSelector(getProfile, p => p?.find(c => c.claimType == claimTypes.LOCALE)?.claimValue);
const checkIfTaraLoginMethod = createSelector(State, (data) => { return data.idp?.toLowerCase() == ELoginMethod.TARA });
const checkIfTeliaLoginMethod = createSelector(State, (data) => { return data.idp?.toLowerCase() == ELoginMethod.TELIA });
const isExemptMfa = createSelector(State, (data) => data.idp?.toLowerCase() == ELoginMethod.TELIA || data.idp?.toLowerCase() == ELoginMethod.TARA);
const getUserBasicInfo = createSelector(State, (state) => ({
  givenName: state.given_name,
  familyName: state.family_name,
  synlabId: state.synlab_id,
  claims: state.profile
}))

const hasGeneplanetOrders = createSelector(State, (state) => state.hasGeneplanetOrders);
const isGenePlanetEnrolled = createSelector(State, (state) => state.isGenePlanetEnrolled);
const useNewUI = createSelector(State, (state) => state.config.UseNewUI.toLowerCase() === 'true');
const useNewNav = createSelector(useNewUI, isEETenant, (useNewUiResult, isEETenantResult) => useNewUiResult || isEETenantResult);

const getConsultationUrl = createSelector(getBaseBigcommerceUrl, getEEConsultationUrl, isEETenant, (baseBigcommerceUrl, eeConsultationUrl, isEE) => isEE ? eeConsultationUrl : baseBigcommerceUrl)

export const StsSelectors = {
  getFOBTProductCodeCampaign,
  isCzechRepublic,
  getWhiteLabelName,
  isExemptMfa,
  getConsultationUrl,
  isHuTenant,
  isPTAdminUser,
  useNewNav,
  getEnablePreventionWellness,
  getRedirectURLFromQueryParam,
  isDKTenant,
  isEETenant,
  isFITenant,
  isDFPTenant,
  isNotWhiteLabled,
  getProfile,
  getMFAEnabledValue,
  isSchoolAndABCompany,
  isMassCompany,
  canAccesspoolIndividualOrdersPage,
  isSchoolAdmin,
  isLolliAdmin,
  isLolliUser,
  getFirstName,
  isSchoolTeacher,
  getLastName,
  getAppIsWhiteLabeled,
  getbaseProfileUrl,
  getAppLogo,
  getConfig,
  getEmail,
  getCountry,
  getBaseUrl,
  getBaseAdminUrl,
  getApplicationEnvironment,
  getName,
  getApplicationType,
  getApiEndpoint,
  isPlasmaWebAdmin,
  isCustomerCare,
  canAccessCheckResults,
  canAccessUserManagement,
  getBaseBookingUrl,
  getBaseBigcommerceUrl,
  getEEConsultationUrl,
  getEEHometestUrl,
  getBookingLinkEnabled,
  isSchoolUser,
  getUserId,
  isLabUser,
  getIsInvoiceColumnEnabled,
  isHungaryFootballUser,
  isMassUser,
  getRedirectPath,
  canAccesspoolGenerateOrdersPage,
  isMassTester,
  isCompanyAdmin,
  getRole,
  getSynlabId,
  getClaims,
  getPassport,
  getSocialSecurityNumber,
  getHasPassport,
  getSchoolSynlabId,
  getClaimsLoading,
  getClaimsError,
  getUsertypeWithFrontEndTestTypeFilter,
  isCompanyEmployee,
  patientInformation,
  isAppAccessUser,
  isILISCodeDE,
  getPreventionWellnessConfig,
  getCurrentUserToken,
  isLolliLabAdmin,
  isDFPAdmin,
  isSHFYAdmin,
  fetchedClaimRecord,
  isPreventionWellnessUser,
  isSuperSupport,
  getProfileLocale,
  checkIfTaraLoginMethod,
  checkIfTeliaLoginMethod,
  getUserBasicInfo,
  getRequireMFA,
  hasGeneplanetOrders,
  isGenePlanetEnrolled,
};

function getEnvConfig(
  config: { [keys: string]: any },
  keyName: string
): string {
  if (!config) {
    return "";
  }
  return config[keyName]?.trim();
}

function checkTenant(tenantName: string, config: EnvConfig): boolean {
  const checkConfig = (name: whiteLabelNames) =>
    getEnvConfig(config, configType.WHITE_LABEL_ENABLED).toLowerCase() == claimValue.TRUE &&
    getEnvConfig(config, configType.WHITE_LABEL_NAME).toLowerCase() == name;

  switch (tenantName) {
    case whiteLabelNames.COVID_DK:
      return checkConfig(whiteLabelNames.COVID_DK);
    case whiteLabelNames.ESTONIA:
      return checkConfig(whiteLabelNames.ESTONIA);
    case whiteLabelNames.DFP:
      return checkConfig(whiteLabelNames.DFP);
    case whiteLabelNames.HUNGARY:
      return checkConfig(whiteLabelNames.HUNGARY);
    case whiteLabelNames.SPAIN:
      return checkConfig(whiteLabelNames.SPAIN);
    case whiteLabelNames.FINLAND:
      return checkConfig(whiteLabelNames.FINLAND);
    case whiteLabelNames.CZECH_REPUBLIC:
      return checkConfig(whiteLabelNames.CZECH_REPUBLIC);
    default:
      return false;
  }
}

function isClaimTrue(claim:string, profile = null): boolean {
  if (!profile) return false;
  return profile.find((x) => x.claimType === claim)?.claimValue?.toLocaleLowerCase() == claimValue.TRUE
}
