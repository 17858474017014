<div class="title pt-3">
  <h1>{{ label || 'resultsV2.filter.searchUsers' | transloco}}</h1>
</div>
<form *ngIf="!selected; else showSelected">
  <mat-form-field class="w-100" appearance="outline">
    <mat-label>{{ 'resultsV2.filter.search' | transloco }}</mat-label>
    <input type="text" matInput [formControl]="searchControl" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (click)="select(option)">
        <span class="primary-color">{{option.name}}</span>
        <br>
        <small class="d-flex">
          <span class="m-1">{{option.synlabId}}</span>
          <span class="m-1 break-all">{{option.email}}</span>
        </small>
      </mat-option>
    </mat-autocomplete>
    <div class="search-icon">
      <ng-container *ngIf="!fetching" >
        <img *ngIf="!searchControl.value" src="/assets/images/search-primary-icon.svg"  alt="search-primary-icon"/>
        <img role="button" (click)="clear()" *ngIf="searchControl.value" src="/assets/images/primary-close-icon.svg"  alt="primary-close-icon"/>
       </ng-container>
       <mat-spinner *ngIf="fetching" diameter="18"></mat-spinner>
    </div>
    <mat-error>{{ message }}</mat-error>
  </mat-form-field>
</form>

<ng-template #showSelected>
  <mat-form-field class="w-100" appearance="outline">
    <input type="text" role="button" matInput readonly [value]="displayFn(selected)" (click)="showDetail()">
    <div class="search-icon">
      <img role="button" (click)="clear()" src="/assets/images/primary-close-icon.svg"  alt="primary-close-icon"/>
    </div>
  </mat-form-field>
</ng-template>
