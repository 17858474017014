<ng-container *transloco="let t; read: 'resultsV2'">
  <div class="results-tranfer-dialog">
    <div mat-dialog-title class="dialog-header">
      <div class="icon icon-bg">
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M25.094 0.542235C25.4629 0.911154 25.5828 1.46135 25.4007 1.95029L16.9154 24.7395C16.72 25.2643 16.2175 25.6111 15.6575 25.6075C15.0975 25.604 14.5994 25.2509 14.4106 24.7237L10.8518 14.7844L0.912559 11.2256C0.385327 11.0368 0.0322426 10.5387 0.0287132 9.97873C0.0251838 9.41873 0.371961 8.9162 0.896773 8.72079L23.6859 0.235515C24.1749 0.0534646 24.7251 0.173316 25.094 0.542235ZM13.4347 14.0872L15.6904 20.3873L21.8844 3.7518L5.24896 9.94582L11.549 12.2016L14.7231 9.02752C15.2438 8.50682 16.088 8.50682 16.6087 9.02752C17.1294 9.54821 17.1294 10.3924 16.6087 10.9131L13.4347 14.0872Z" fill="#0077AD"/>
        </svg>
      </div>
      <div class="title-wrapper">
        <small class="dialog-sub-title">{{ form.valid ? t('checkResults'): t('transfer.dialog.subTitle') }}</small>
        <span class="dialog-main-title">
            {{ (form.get('destination').valueChanges | async) ?
            (form.valid ? t('transfer.dialog.subTitle') : t('transfer.dialog.titleFill'))
            :  t('transfer.dialog.titleSearch') }}
        </span>
      </div>
      <button class="icon-bg icon-bg-small" mat-dialog-close>&times;</button>
    </div>
    <mat-dialog-content>
      <p class="main-text">{{ t('transfer.dialog.body') }}</p>
      <form [formGroup]="form" [class.transferring]="transferring$|async">
        <app-results-v2-people-filter
          [label]="t('transfer.dialog.destination')" [fullUser]="true"
          formControlName="destination">
        </app-results-v2-people-filter>
        <ng-container *ngIf="form.get('destination').value">
          <label class="input-label">{{ t('transfer.dialog.reason') }}</label>
          <mat-form-field appearance="outline" class="input-field">
            <input matInput formControlName="reason">
            <div class="clear-icon" *ngIf="form.get('reason').value">
              <img role="button" (click)="clearReason()" src="/assets/images/primary-close-icon.svg"  alt="primary-close-icon"/>
            </div>
          </mat-form-field>
        </ng-container>
      </form>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button class="submit_transfer" [disabled]="form.invalid || (transferring$|async)" (click)="submit()">
        <mat-spinner *ngIf="transferring$|async" [diameter]="24"></mat-spinner>
        <img *ngIf="!(transferring$|async)"  src="/assets/images/right-arrow-white-icon.svg" alt="right-arrow-white-icon"/>
        <span>{{ (transferring$|async) ? t('transfer.dialog.transferInProgress') : t('transfer.dialog.transfer') }}</span>
      </button>
    </mat-dialog-actions>

    <div class="blocker" *ngIf="(transferring$|async)"></div>
  </div>

</ng-container>
