export enum whiteLabelNames {
  COVID_DK = "covid-dk",
  ESTONIA = "ee",
  DFP = "dfp",
  SHFY = "shfy",
  SPAIN = "es",
  SPAIN_TELECOUNSELING = "es-tc",
  HUNGARY = "hu",
  CZECH_REPUBLIC = "cz",
  FINLAND = "fi"
}

export enum userTypes {
  BCP = "bcp",
  MASS_COMPANY = "mass_company",
  WEBREQ_USER = "webreq_user",
  LOLLI_LAB_USER = "lolli_lab_user",
  SCHOOL_ADMIN = "school_admin",
  SCHOOL_TEACHER = "school_teacher",
  LOLLI_SUPER_ADMIN = "lolli_superadmin",
  SYNLAB_ACCESS_USER = "synlab_access_user",
  CUSTOMER_CARE = "customer_care",
  MASS_TESTER = "mass_tester",
  HUNGARY_FOOTBALL = "hungary_football",
  MASS_BAVARIA = "mass_bavaria",
  MASS_PORSCHE = "mass_porsche",
  PT_ADMIN = "pt_admin",
  EE_ADMIN = "ee_admin",
  SUPERADMIN = "superadmin",
  USERADMIN = "useradmin",
  SYNLAB_ACCESS_IDENTIFIER = "synlab_access_verifier",
  PATIENT = "patient",
  DKAMIN = "dk_admin",
  SYNLAB_ACCESS_VERIFIER = "synlab_access_verifier",
  LOLLI_LAB_ADMIN = "lolli_labadmin",
  UK_DFP_ADMIN = "uk_dfp_admin",
  UK_SHFY_ADMIN = "uk_shfy_admin",
  BOOKING_ADMIN_BCP_COORDINATOR = "booking_admin_bcp_coordinator",
  BOOKING_ADMIN_BCP_CLERK = "booking_admin_bcp_clerk",
}

export enum configType {
  WHITE_LABEL_ENABLED = "WhiteLabelEnabled",
  WHITE_LABEL_NAME = "WhiteLabelName",
  TYPE = "Type",
  ENVIRONMENT = "Environment",
  BASE_PLASMA_URL = "basePlasmaUrl",
  BASE_ADMIN_URL = "baseAdminUrl",
  BASE_BOOKING_URL = "baseBookingUrl",
  BASE_BIGCOMMERCE_URL = "baseBigcommerceUrl",
  BOOKING_LINK_ENABLED = "bookingLinkEnabled",
  BASE_PROFILE_URL = "baseProfileUrl",
  ENABLE_PREVENTION_WELLNESS = "EnablePreventionWellness",
  ENABLE_FULLCATALOGUE_VIEW = "FullCatalogueViewEnabled",
  COOKIE_BOT_API_KEY = "CookieBotAPIKey",
  EE_CONSULTATION_URL = "EeBookConsultationUrl",
  EE_HOMETES_URL = "EeBookHomeTestUrl",
  FOBT_PRODUCT_CODE_CAMPAIGN = "FOBTProductCodeCampaign"
}

export enum claimTypes {
  TWO_FACTOR_ENABLED = "two_factor_enabled",
  DATE_OF_BIRTH = "date_of_birth",
  SCHOOL_SYNLAB_ID = "school_synlab_id",
  ILIS_CODE = "ilis_code",
  GENEPLANET_USER = "geneplanet_user",
  ANONYMOUS_USER = "anonymous_user",
  FAMILY_NAME = "family_name",
  GIVEN_NAME = "given_name",
  ALLOW_DELETE_USER = "allow_delete_user",
  LOCALE = "locale",
  LOGIN_METHOD = "login_method",
  SYNLAB_ID = "synlab_id",
  COUNTRY_STORAGE = "country",
  PASSPORT_NUMBER = "passport_number",
  COUNTRY_RESIDENCE = "country_residence",
  NATIONALITY = "nationality",
  EE_NOTIFICATION_LANGUAGE = "ee_notification_language",
  SSN = "ssn",
  EMAIL = "email",
  NO_EMAIL = "no_email",
  USER_TYPE = "user_type",
  USERID = "userId"
}

export enum claimValue {
  TRUE = "true",
  FALSE = "false",
  EMPTY_STRING = "",
  GENDER_FEMALE = "female",
  GENDER_MALE = "male",
  GENDER_UNKNOWN = "unknown",
  DE_ILIS_CODE_SUFFIX = "DE"
}

export enum appLogo {
  LOADING = "/assets/project-logo/loading-logo.png",
  COVID_DK = "/assets/images/new/branding-logo.png",
  SYNLAB = "/assets/project-logo/default-logo.png",
}

export enum countries {
  PORTUGAL = "pt",
  ESTONIA = "ee"
}

export enum pageTitle {
  COVID_DK = "Covidresults.dk Web",
  SYNLAB = "SYNLAB Access",
  ESTONIA = "SYNLAB Access"
}

export enum EBodyClass {
  NORMAL = "page-normal",
  COVID_DK = "white-labeled-dk",
}

export enum modalConfig {
  MODAL_WIDTH_50REM = "50rem",
  MODAL_HEIGHT_AUTO = "auto",
  MODAL_PANEL_CLASS_NO_PADDING = "no-padding",
  VIEWPORT_WIDTH_60 = "60vw"
}

export enum dataFormats {
  DATE_FORMAT = "DD.MM.YYYY",
  DATE_FORMAT_BE = "YYYY-MM-DD",
  TIME_FORMAT = "HH:mm"
}

export enum preventionWellness {
  AUTH_CHECK_INTERVAL_TIME = 60000 * 5 // in milliseconds
}

export enum apiErrorCode {
  USER_SYNLABID_NOTFOUND = 'COMPANY_INDIVIDUALTEST_USER_SYNLABID_NOTFOUND',
  USER_SCHOOLSYNLABID_NOTFOUND = 'COMPANY_INDIVIDUALTEST_USER_SCHOOLSYNLABID_NOTFOUND',
  PATIENT_SYNLABID_NOTFOUND = 'COMPANY_INDIVIDUALTEST_PATIENT_SYNLABID_NOTFOUND',
  PATIENT_SCHOOLSYNLABID_NOTFOUND = 'COMPANY_INDIVIDUALTEST_PATIENT_SCHOOLSYNLABID_NOTFOUND',
  PATIENT_USER_SCHOOLSYNLABID_NOTMATCHED = 'COMPANY_INDIVIDUALTEST_PATIENT_USER_SCHOOLSYNLABID_NOTMATCHED',
  TESTKIT_BARCODE_ALREADY_EXIST = 'TestTubeIdentifier already exists.'
}

export enum externalLink {
  WAS_URL = "https://www.was.digst.dk/covidresults-dk",
  GP_PRIVACY_POLICY = "https://geneplanet.com/eu/privacy-policy",
  GP_TERMS_AND_CONDITIONS = "https://geneplanet.com/eu/terms-and-conditions",
  GP_DATA_PRIVACY = "https://geneplanet.com/eu/data-privacy",
}

export enum ELoginMethod {
  SYNLAB_ACCESS = "synlab_access",
  TARA = "tara",
  TELIA = "telia",
}

export enum ECertificate {
  EE_CERTIFICATE = "ee-certificate"
}

export enum ELanguage {
  ENGLISH = 'en',
  RUSSIAN = "ru",
  ESTONIAN = "et"
}

export enum EBCP47LanguageCode {
  ENGLISH = 'en-US',
  RUSSIAN = "ru-RU",
  ESTONIAN  = "et-EE"
}

export const GTM_PLACEHOLDER_KEY = '[placeholder]';
