<div class="container py-4">
  <div class="page-title-wrap">
    <div id="patientList" class="page-title p-0 m-0"><em class="fas fa-users"></em> Patients</div>
  </div>
  <div class="wrapper user-container mb-150 pl-box-shadow-1">
    <div class="wrapper">
      <form>
        <div class="px-4">
          <div class="form-group form-inline my-4">
            <div class="form-group has-search">
              <span class="form-control-feedback">
                <img src="assets/images/search.png" alt="" class="img-fluid" />
              </span>
              <input type="text" class="form-control search-input" placeholder="" name="searchTerm"
                [(ngModel)]="state.searchTerm" (input)="state.page = 1; load()"
                placeholder="Search by Name, Email or Social Security No." />
              <div class="input-group-append clear-icon" *ngIf="state.searchTerm">
                <button class="btn btn-default" type="button" (click)="clear()">
                  <img src="assets/images/close.png" alt="" class="img-fluid close-btn" />
                </button>
              </div>
            </div>

            <div class="ml-auto actions">
              <button type="button" [routerLink]="['/create-new-patient']" class="btn btn-default px-4 std-button">
                Create
              </button>
            </div>
          </div>
        </div>

        <div class="px-4 py-4 pl-bg-2 table-wrapper">
          <table class="table" aria-describedby="patientList">
            <thead>
              <tr>
                <th scope="col" sortable="clientName">
                  SYNLAB ID
                </th>
                <th scope="col" sortable="name">
                  Name
                </th>
                <th scope="col" sortable="clientName">
                  Email
                </th>
                <th scope="col" sortable="clientName">
                  Social Security No.
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="no-hover" [ngSwitch]="listStatus">
                <td colspan="4" class="text-center spinner" *ngSwitchCase="1">
                  <p class="py-5">
                    <img src="assets/images/spinner.gif" alt="spinner" class="img-fluid spinner spinner-image" />
                  </p>
                </td>
                <td colspan="4" class="text-center" *ngSwitchCase="2">
                  <p class="py-4">Your search gave no result.</p>
                </td>
                <td colspan="4" class="text-center" *ngSwitchCase="3">
                  <p class="py-4">No records found!</p>
                </td>
              </tr>

              <tr *ngFor="let user of patient" [class.inactive-label]="user.active == 'False'">
                <td>
                  <div>
                    <ngb-highlight [result]="user.patientIndex" [term]="state.searchTerm"></ngb-highlight>
                  </div>
                </td>
                <td>
                  <span *ngIf="user.emailConfirmed == 'False'">User has not accepted invitation</span>
                  <ngb-highlight *ngIf="user.emailConfirmed == 'True'" [result]="user.name" [term]="state.searchTerm">
                  </ngb-highlight>
                </td>
                <td>
                  <ngb-highlight [result]="user.email" [term]="state.searchTerm"></ngb-highlight>
                </td>
                <td>
                  <ngb-highlight [result]="user.ssn" [term]="state.searchTerm"></ngb-highlight>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
      <div *ngIf="total" class="d-flex p-2 flex-row-reverse">
        <span class="pagination-wrapper pagination-left">
          <div class="pagination-template">
            <app-pagination [page]="state.page" [pageSize]="state.pageSize" [total]="total"
              [resultCount]="patient.length" (pageChange)='onPageChange($event)'>
            </app-pagination>
          </div>
        </span>
      </div>
    </div>
  </div>

</div>