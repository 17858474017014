import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { catchError, switchMap, tap, withLatestFrom, map } from "rxjs/operators";
import { ApiService } from "src/app/service/api/api.service";
import { ResultActionsV2 } from "../actions/results.actionsV2";
import { ResultV2Selectors } from "../selectors/resultsV2.selectors";
import { AppState } from "../reducers";
import { StsSelectors } from "../selectors/sts.selectors";
import { TranslocoService } from "@ngneat/transloco";
import { EMPTY, of, combineLatest } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { Claim, UserAccountInfo } from "src/app/models/claims.model";
import { claimTypes } from "src/app/appsettings";
import { ResultV2 } from "src/app/pages/results-v2/model/resultv2";
import { MatDialog } from "@angular/material/dialog";
import { ResultsV2TransferDialogComponent } from "src/app/pages/results-v2/page/detail/results-transfer-dialog/results-transfer-dialog.component";


@Injectable()
export class ResultsV2Effects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private api: ApiService,
    private translocoService: TranslocoService,
    private toastr: ToastrService,
    private dialog: MatDialog,
  ) { }

  showResultsTransferDialog$ = createEffect(
    () => this.actions$.pipe(
      ofType(ResultActionsV2.resultsTransferShowDialog),
      withLatestFrom(this.store.pipe(select(ResultV2Selectors.getSupportViewSelectedUser))),
      switchMap(([action, synlabId]) => {
        return this.dialog.open(ResultsV2TransferDialogComponent, {
          data: synlabId,
          disableClose: true,
          width: '480px',
          panelClass: 'transfer-dialog'
        }).afterClosed()
      }),
      map(OK => OK ? ResultActionsV2.resultsTransferComplete() : ResultActionsV2.resultsTransferCancel())
    ),
  )

  resultTransfer$ = createEffect(
    () => this.actions$.pipe(
      ofType(ResultActionsV2.resultsTransferStart),
      withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
      switchMap(([action, endpoint]) => {
        const { destination, origin, reason } = action;
        return this.api.postMethod(`${endpoint}/api/results/v2/transfer-result`, {
          transferFrom: origin,
          transferTo: destination,
          reasonForTransfer: reason
        }).pipe(catchError(() => {
          return of(false);
        }))
      }),
      map(OK => OK ? ResultActionsV2.resultsTransferSuccessful() : ResultActionsV2.resultsTransferFailure())
    ),
  )

  showResultsTransferSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(ResultActionsV2.resultsTransferSuccessful),
      tap(() => this.toastr.success(this.translocoService.translate('resultsV2.transfer.success')))
    ),
    { dispatch: false}
  )

  showResultsTransferError$ = createEffect(
    () => this.actions$.pipe(
      ofType(ResultActionsV2.resultsTransferFailure),
      tap(() => this.toastr.error(this.translocoService.translate('resultsV2.transfer.failure')))
    ),
    { dispatch: false}
  )

  loadResultss$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultActionsV2.loadResultssV2),
        withLatestFrom(
          this.store.pipe(select(StsSelectors.getApiEndpoint)),
          this.store.pipe(select(StsSelectors.getSynlabId)),
          this.store.pipe(select(StsSelectors.isEETenant)),
        ),
        switchMap(([_ , endpoint, loggedInUserSynlabId, isEE]) => {
          let language = this.translocoService.getActiveLang();
          if (isEE && language === 'en') {
            language = 'en-GB';
          }
          let path = `${endpoint}/api/results/v2?language=${language}`;
          return this.api
            .getMethod(path).pipe(
              catchError(() => {
                this.store.dispatch(
                  ResultActionsV2.loadResultssFailureV2()
                );
                return EMPTY;
              })
           );
        }),
        withLatestFrom(
          this.store.pipe(select(StsSelectors.getAppIsWhiteLabeled))
        ),
        tap(([results, _]) => {
          this.store.dispatch(
            ResultActionsV2.loadResultssSuccessfulV2({ results })
          );
        }),
      ),
    { dispatch: false }
  );

  loadResultsForWardBySynlabId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultActionsV2.loadResultsForWard),
        withLatestFrom(
          this.store.pipe(select(StsSelectors.getApiEndpoint))
        ),
        switchMap(([{ synlabId }, endpoint]) => {
          let language = this.translocoService.getActiveLang();
          let path = `${endpoint}/api/results/v2?language=${language}`;
          return this.api
          .getMethod(path)
          .pipe(
            // map(x => x.filter(result => result.patientSynlabId === synlabId)),
            catchError(() => {
            this.store.dispatch(
              ResultActionsV2.loadResultssFailureV2()
            );
            return EMPTY;
          }));
        }),
        withLatestFrom(
          this.store.pipe(select(StsSelectors.getAppIsWhiteLabeled))
        ),
        tap(([results, _]) => {
          this.store.dispatch(
            ResultActionsV2.loadResultssSuccessfulV2({ results })
          );
        }),
      ),
    { dispatch: false }
  );

  loadResultsError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultActionsV2.loadResultssFailureV2),
        tap(() => this.toastr.error(this.translocoService.translate('error.REQUEST_ERROR')))
      ),
    { dispatch: false }
  )

  fetchClaims(userId: string) {
    return this.api.get<Claim[]>(`/manage/accounts/${userId}`);
  }

  fetchSupportViewSelectedSynlabIdClaims$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultActionsV2.fetchSupportViewSelectedSynlabIdClaims),
        switchMap(({ synlabId, results }) =>
          combineLatest([this.api.get<{ users: UserAccountInfo[] }>(`/manage/accounts/synlabid/${synlabId}`), of(results)])
        ),
        switchMap(([u,r]) =>
          combineLatest([this.fetchClaims(u.users[0].id), of(r)])),
        tap(([claims, results]) => {
          const passport = claims.find(c => c.claimType == claimTypes.PASSPORT_NUMBER)?.claimValue;
          const hasPassport = typeof passport == 'string' && passport.trim().length > 0;
          this.store.dispatch(ResultActionsV2.setSupportViewSelectedPatientHasPassport({ hasPassport }));
          this.store.dispatch(ResultActionsV2.loadResultssSuccessfulV2({results}));
        })
      ),
    { dispatch: false }
  )

  loadResultsAdmin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultActionsV2.loadResultsV2Admin),
        withLatestFrom(
          this.store.pipe(select(StsSelectors.getApiEndpoint)),
          this.translocoService.langChanges$,
        ),
        switchMap(([{ synlabId }, endpoint, language = 'en']) => {
          let path = `${endpoint}/api/results/v2/admin`;
          return this.api
            .getMethod <ResultV2[]>(path, {
              synlabId,
              language,
              page: 1,
              pageSize: 999
            })
            .pipe(catchError(() => {
              this.store.dispatch(
                ResultActionsV2.loadResultssFailureV2()
              );
              return EMPTY;
            }));
        }),
        withLatestFrom(
          this.store.pipe(select(ResultV2Selectors.getSupportViewSelectedUser))
        ),
        tap(([results, synlabId]) => {
          if (synlabId && results && results.length > 0) {
            if (results.some(r => r.indicators.some(i => i.hasCertificate))) {
              this.store.dispatch(ResultActionsV2.fetchSupportViewSelectedSynlabIdClaims({ synlabId, results }))
            } else {
              this.store.dispatch(ResultActionsV2.loadResultssSuccessfulV2({results}));
            }
          } else {
            this.store.dispatch(ResultActionsV2.loadResultssSuccessfulV2({results}));
          }
        }),
      ),
    { dispatch: false }
  );
}
