import { inject, NgModule } from "@angular/core";
import { Routes, RouterModule, ROUTES } from "@angular/router";
import { IndexComponent } from "./pages/index/index.component";
import { AuthGuard } from "./service/authGuard/auth.guard";
import { FindPatientComponent } from "./pages/find-patient/find-patient.component";
import { InitComponent } from "./pages/init/init.component";
import { SiginCallbackComponent } from "./pages/signin-callback/signin-callback.component";
import { LoginRedirectComponent } from "./pages/login-redirect/login-redirect.component";
import { TestResultComponent } from "./pages/test-result/test-result.component";
import { TestResultDetailComponent } from "./pages/test-result-detail/test-result-detail.component";
import { SearchPatientComponent } from "./pages/search-patient/search-patient.component";
import { RenewtokenComponent } from "./pages/renewtoken/renewtoken.component";
import { UnauthorizeComponent } from "./pages/unauthorize/unauthorize.component";
import { CountryGuard } from "./service/countryGuard/country.guard";
import { UserTypeGuard } from "./service/user-type/user-type.guard";
import { NotFoundGuard } from "./service/not-found/not-found.guard";
import { RegisterAndTestComponent } from "./pages/register-and-test/register-and-test.component";
import { IsTeacherGuard } from "./service/is-teacher.guard";
import { LabOrderComponent } from "./pages/lab-order/lab-order.component";
import { IsLabUserGuard } from "./service/is-lab-user.guard";
import { NotFoundPageComponent } from "./pages/not-found-page/not-found-page.component";
import { MFAGuard } from "./service/guards/mfa.guard";
import { RedirectComponent } from "./pages/redirect/redirect.component";
import { WasRedirectComponent } from "./pages/was-redirect/was-redirect.component";
import { ConfigService } from "./service/config/config.service";
import { PtEeGuard } from "./service/guards/pt-ee.guard";

function routesFactory(config: ConfigService = inject(ConfigService)) {
  const routes: Routes = [
    {
      path: "",
      component: InitComponent,
      canActivate: [AuthGuard],
      data: {
        allowedUsers: ["all"],
      },
      pathMatch: "full",
    },
    {
      path: "signin-callback",
      component: SiginCallbackComponent,
      data: {
        name: "init",
        allowedUsers: ["all"],
      },
    },
    {
      path: "redirect",
      component: RedirectComponent,
    },
    {
      path: "renewtoken",
      component: RenewtokenComponent,
      data: {
        allowedUsers: ["all"],
        name: "init",
      },
    },

    {
      path: "unauthorized",
      component: UnauthorizeComponent,
      data: {
        name: "unauthorized",
      },
    },
    {
      path: "was",
      component: WasRedirectComponent
    },
    {
      path: "WAS",
      redirectTo: 'was'
    },
    {
      path: "home",
      canActivate: [AuthGuard, UserTypeGuard, CountryGuard, PtEeGuard, MFAGuard],
      component: LoginRedirectComponent,
      data: {
        allowedUsers: ["all"],
      },
    },
    {
      path: "create-new-patient",
      canActivate: [AuthGuard, UserTypeGuard, CountryGuard, PtEeGuard, MFAGuard],
      component: IndexComponent,
      data: {
        allowedUsers: ["all"],
      },
    },
    {
      path: "find-patient",
      canActivate: [AuthGuard, UserTypeGuard, CountryGuard,  PtEeGuard, MFAGuard],
      component: FindPatientComponent,
      data: {
        allowedUsers: ["all"],
      },
    },
    {
      path: "search-patient",
      canActivate: [AuthGuard, UserTypeGuard, CountryGuard,  PtEeGuard, MFAGuard],
      component: SearchPatientComponent,
      data: {
        allowedUsers: ["all"],
      },
    },
    {
      path: "my-test-results",
      canActivate: [AuthGuard, UserTypeGuard, CountryGuard,  PtEeGuard, MFAGuard],
      component: TestResultComponent,
      data: {
        allowedUsers: ["all"],
      },
    },
    {
      path: "test-results-detail",
      canActivate: [AuthGuard, UserTypeGuard, CountryGuard,  PtEeGuard, MFAGuard],
      component: TestResultDetailComponent,
      data: {
        allowedUsers: ["all"],
      },
    },
    {
      path: "results",
      canActivate: [AuthGuard, UserTypeGuard, CountryGuard,  PtEeGuard, MFAGuard],
      loadChildren: () =>
        String(config._envConfig.FullCatalogueViewEnabled).toLocaleLowerCase() == "true" ?
          import('./pages/results-v2/results-v2.module').then(module => module.ResultsV2Module) :
          import("./pages/results/results.module").then((d) => d.ResultsModule)
      ,
      data: {
        allowedUsers: ["all"],
      },
    },
    {
      path: "results/v2",
      canActivate: [AuthGuard, UserTypeGuard],
      loadChildren: () => import('./pages/results-v2/results-v2.module').then(module => module.ResultsV2Module),
      data: {
        allowedUsers: ["all"],
      }
    },
    {
      path: "register-and-test",
      canActivate: [
        AuthGuard,
        UserTypeGuard,
        CountryGuard,
        IsTeacherGuard,
        PtEeGuard,
        MFAGuard,
      ],
      component: RegisterAndTestComponent,
      data: {
        allowedUsers: ["school_teacher"],
      },
    },
    {
      path: "lab-order",
      canActivate: [
        AuthGuard,
        UserTypeGuard,
        CountryGuard,
        IsLabUserGuard,
        PtEeGuard,
        MFAGuard,
      ],
      component: LabOrderComponent,
      data: {
        allowedUsers: ["webreq_user", "bcp"],
      },
    },
    {
      path: "individual",
      canActivate: [AuthGuard, UserTypeGuard, CountryGuard, MFAGuard],
      loadChildren: () =>
        import("./pages/individual-test/individual-test.module").then(
          (d) => d.IndividualTestModule
        ),
      data: {
        allowedUsers: ["all"],
      },
    },
    {
      path: "generate",
      canActivate: [AuthGuard, UserTypeGuard, CountryGuard, MFAGuard],
      loadChildren: () =>
        import("./pages/generate-order/generate-individual-order.module").then(
          (d) => d.GenerateIndividualOrderModule
        ),
      data: {
        allowedUsers: ["all"],
      },
    },
    {
      path: "wardresult",
      loadChildren: () =>
        import(
          "./pages/parent-child-view-result/parent-child-view-result.module"
        ).then((d) => d.ParentChildViewResultModule),
    },
    {
      path: "wr",
      redirectTo: "wardresult",
    },
    {
      path: "createpoolorder",
      canActivate: [AuthGuard, CountryGuard, MFAGuard],
      loadChildren: () =>
        import("./pages/pool-order/pool-order.module").then(
          (m) => m.PoolOrderModule
        ),
    },
    /** temporarily hide create account. uncomment for local development. */
    {
      path: "createaccount",
      canActivate: [AuthGuard, CountryGuard],
      loadChildren: () =>
        import("./pages/create-account/create-account.module").then(
          (a) => a.CreateAccountModule
        ),
    },
    {
      path: "individualtesting",
      canActivate: [AuthGuard, CountryGuard],
      loadChildren: () =>
        import("./pages/company-individual-testing/company-individual-testing.module").then(
          (a) => a.CompanyIndividualTestingModule
        ),
    },
    {
      path: "not-found",
      component: NotFoundPageComponent,
    },
    {
      path: "**",
      canActivate: [NotFoundGuard],
      component: InitComponent,
      pathMatch: "full",
    },
  ];
  return routes;
}

@NgModule({
  imports: [RouterModule.forRoot([], {})],
  providers: [{
    provide: ROUTES,
    useFactory: routesFactory,
    multi: true,
    deps: [ConfigService]
  }],
  exports: [RouterModule],
})
export class AppRoutingModule {}
