<div class="user-info-card pt-3">
  <h3>{{ user.name }}</h3>
  <div class="user-info-card-info">
    <strong>{{'checkResult.selectUserInformation.synlabIdLabel'|transloco}}:</strong>
    <span class="text-truncate" [title]="user.synlabId ">{{ user.synlabId  }}</span>
    <strong>{{'checkResult.selectUserInformation.emailLabel'|transloco}}:</strong>
    <span class="text-truncate" [title]="user.email">{{ user.email }}</span>
  </div>
  <button class="close">
    <img (click)="close()" src="/assets/images/primary-close-icon.svg" alt="primary-close-icon"/>
  </button>
  <button class="close-button" (click)="close()">
    {{'resultsV2.filter.close'|transloco}}
  </button>
</div>
